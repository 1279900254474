<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold"><i class="fad fa-plus-square mr-2"></i>Add Users</h3>
      <v-btn @click="slideoutsDiscard()" icon><i class="far fa-times"></i></v-btn>
    </template>
    <v-container fluid class="pa-3">
      <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
        <div class="justify-end d-flex mb-4" style="gap: 0.5rem">
          <v-text-field
            v-model="options.search"
            label="Search Users"
            dense
            solo
            hide-details
            clearable
            prepend-inner-icon="far fa-search"
            :style="{ 'max-width': $vuetify.breakpoint.lgAndUp ? '250px' : 'auto' }"
          >
          </v-text-field>
        </div>

        <v-data-table
          dense
          :headers="headers"
          v-model="slideouts.data"
          :items="entities"
          show-select
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="15"
          checkbox-color="info"
          :loading="loadingStates.table"
          class="elevation-2"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'far fa-arrow-to-left',
            lastIcon: 'far fa-arrow-to-right',
            prevIcon: 'far fa-angle-left',
            nextIcon: 'far fa-angle-right',
            itemsPerPageOptions: [15, 30, 50, 100],
          }"
          item-key="id"
        >
          <template v-slot:no-data>
            <img width="300" src="/img/art/fogg-no-connection-2.png" />
            <p class="font-weight-bold">No Data Available!</p>
          </template>

          <template v-slot:[`item.data-table-select`]="{ item, index, isSelected, select }">
            <v-simple-checkbox
              color="info"
              :ripple="false"
              :value="isUserDisabled(item) ? true : isSelected"
              :disabled="isUserDisabled(item)"
              @click="isUserDisabled(item) ? false : select(!isSelected)"
              :key="'user-select-' + index"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.firstName`]="{ item }">
            <v-row style="margin: 5px 0px">
              <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
            </v-row>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>

          <template v-slot:[`item.roles`]="{ item }">
            <v-row style="margin: 5px 0px">
              <v-chip
                style="font-size: 11px; font-weight: 600"
                v-for="role in item.roles"
                :key="role.name"
                label
                x-small
                color="deep-purple"
                text-color="white"
                :ripple="false"
                link
              >
                {{ role.name }}
              </v-chip>
            </v-row>
          </template>

          <template v-slot:[`item.joinDate`]="{ item }">
            <dater :date="item.joinDate" date-only></dater>
          </template>

          <template v-slot:loading>
            <video width="250" muted loop autoplay style="padding: 0.5rem">
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Searching the Cosmos...</p>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideoutsDiscard()"> <i class="fal fa-chevron-left mr-2"></i>Discard </v-btn>
        <v-btn color="info" :disabled="!slideouts.data.length" @click="slideoutsConfirmed()">
          <i class="fal fa-check mr-2"></i> Add
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>
<script>
import UserServices from "../../../features/Admin/services/StaffService";
import userHeader from "../../../features/Admin/config/tables/user.header";

export default {
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    disabledUsers: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slideouts: {
        active: false,
        data: [],
        hasChanges: false,
      },
      headers: this.cloneDeep(userHeader).splice(1, userHeader.length - 1),
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["id"],
        sortDesc: [true],
      },
      loadingStates: {
        table: false,
      },
      entities: [],
      total: 0,
      userTimer: null,
    };
  },
  methods: {
    open() {
      this.slideouts.active = true;
    },
    isUserDisabled(user) {
      return this.disabledUsers.findIndex((u) => u.id == user.id) != -1;
    },
    slideoutsDiscard() {
      this.slideouts.active = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }
      //reset the changes
      this.slideouts.data = [];
      this.$releaseChanges();
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    slideoutsConfirmed() {
      this.$emit("confirm", this.slideouts.data);
      this.slideouts.data = [];
      this.slideouts.hasChanges = false;
      this.slideoutsDiscard();
    },
    checkForChanges() {
      if (!this.slideouts.active) return;
      this.slideouts.hasChanges = this.slideouts.data.length > 0;
      if (this.slideouts.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    getDataDebounced() {
      if (this.userTimer == null) {
        this.userTimer = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.userTimer);

      // delay new call 400ms
      this.userTimer = setTimeout(() => {
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      this.$backToTop();
      UserServices.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
  },
  watch: {
    "slideouts.data": {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },

    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>
